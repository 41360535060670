import axios from "axios"
import api from "@/store/modules/api"

export const state = {
  data: [],
  activity: setActivity(),
  tableHeader: [
    {name: 'name'},
    {name: 'client'},
    // {name: 'phase'},
    {name: 'type'},
    {name: 'priority'},
    // {name: 'status'},
    {name: 'team'},
  ],
  priorityList: [
    'regular',
    'important',
    'urgent'
  ],
  typeList: [
    'app',
    'refactor',
    'feature',
    'business',
    'project',
    'prototype',
    'UX/UI',
    'UI',
    'fix',
    'study'
  ],
  phaseList: [
    'discovery',
    'design',
    '??? find dev',
    'implementation',
    'ask offer',
    'send details',
    'development',
    'client review',
    'waiting answer',
    'polish',
    'done'
  ],
  teamMembers: [
    'Andrei', 'Marius', 'Cristian', 'Andreea', 'Ruben', 'Iulian', 'Carla Bunta', 'Carla Bob'
  ],
  statusList: [
    {label: 'not started'},
    {label: 'hold'},
    {label: 'active'},
    {label: 'completed'}
  ],
  filter: '',
  filterList: ['all', 'active', 'not started', 'completed'],
  stages: ['discovery', 'design', 'user-testing', 'development', 'testing', 'bugfix', 'deployment']
}

export const mutations = {
  SET_DATA (state, data) {
    state.data = data
  },
  SET_ACTIVITY_FIELD (state, {name, value}) {
    state.activity[name] = value
  },
  SET_ACTIVITY (state, data) {
    Object.keys(data).forEach(key => {
      state.activity[key] = data[key]
    })
  },
  UPDATE_ACTIVITY (state, data) {
    const index = state.data.findIndex((o) => o._id === data._id);
    state.data[index] = data;
  },
  DELETE_ACTIVITY (state, id) {
    const index = state.data.findIndex((o) => o._id === id);
    state.data.splice(index, 1);
  },
  RESET_ACTIVITY (state) {
    state.activity = setActivity()
  },
  ADD_ACTIVITY (state, data) {
    state.data.push(data)
  },
  SET_FILTER (state, {name, value}) {
    state.filter = value ? name : ''
  },
  HANDLE_TEAM_MEMBER (state, {name, value}) {
    const index = state.activity.teamMembers.findIndex((val) => val === name)
    value
      ? state.activity.teamMembers?.push(name)
      : state.activity.teamMembers?.splice(index, 1)
  },
  HANDLE_STAGES (state, {name, value}) {
    const index = state.activity.stages?.findIndex((val) => val === name)
    value
      ? state.activity.stages?.push(name)
      : state.activity.stages?.splice(index, 1)
  }
}

export const actions = {
  async get_activities ({commit}) {
    try {
      const { data } = await axios.get(api.activity)
      commit('SET_DATA', data)
    } catch (err) {
      console.log(err)
    }
  },
  async add_activity ({state, commit}) {
    try {
      const { data: { id } } = await axios.post(api.activity, {...state.activity, stages: []})
      commit('ADD_ACTIVITY', {...state.activity, _id: id});
      commit('RESET_ACTIVITY');
      commit('SET_MODAL', false);
    } catch (err) {
      console.log(err)
    }
  },
  async update_activity ({state, commit}) {
    try {
      const { data } = await axios.put(`${api.activity}/${state.activity._id}`, state.activity)
      commit('UPDATE_ACTIVITY', data);
      commit('RESET_ACTIVITY');
      commit('SET_MODAL', false);
    } catch (err) {
      console.log(err)
    }
  },
  async delete_activity ({state, commit}) {
    try {
      await axios.delete(`${api.activity}/${state.activity._id}`)
      commit('DELETE_ACTIVITY', state.activity._id);
      commit('RESET_ACTIVITY');
      commit('SET_MODAL', false);
    } catch (err) {
      console.log(err)
    }
  }
}

export const getters = {
  sortByClient: (state) => {
    let results = state.data.sort((a, b) => {
      const clientA = a.client?.toLowerCase();
      const clientB = b.client?.toLowerCase();

      if (clientA < clientB) return -1;
      if (clientA > clientB) return 1;
      return 0;
    })
    switch (state.filter) {
      case 'all':
        return results;
      case 'active':
        results = results.filter((o) => o.status === 'active');
        break
      case 'not started':
        results = results.filter((o) => o.status === 'not started');
        break
      case 'completed':
        results = results.filter((o) => o.status === 'completed');
        break
      default:
        results = results.filter((o) => o.status !== 'completed');
    }
    return results;
  }
}

function setActivity () {
  return {
    name: '',
    status: '',
    teamMembers: [],
    stages: [],
    stagesOption: true
  }
}